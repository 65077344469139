import * as React from "react";
import { Parallax } from "react-parallax";
import { Card, Container, Row } from "react-bootstrap";

import image from "../images/Storybackground.png";
import Layout from "../components/layout";
import Section from "../components/section";
import Typography from "../components/typography";

const MissionStatement = () => {
	return (
		<Layout nextPage={{ link: "/banks", text: "Banks & Credit Unions" }}>
			<Section size="md" color="light" staticHeight>
				<Parallax
					className="d-flex justify-content-center align-items-center h-100"
					bgImage={image}
					strength={750}>
					<Container className="text-center h-100">
						<div>
							<Typography type="head">Our Mission</Typography>
						</div>
					</Container>
				</Parallax>
			</Section>
			<Section size="sm" color="dark" bg="light">
				<Container className=" py-5">
					<Typography type="paragraph">
					&emsp;At Fundingverse, we believe in giving small business owners an ultra-efficient way to acquire the financing 
					they need so that they can spend more time growing their business. Forget the stress and headache of figuring 
					out funding: We simplify the lending process and put time back in the hands of business owners so that they 
					can create a powerful and positive impact in their communities. We recognize that access to capital is vital 
					to small businesses. 
					<br></br>
					<br></br>
					<b>
						Did you know that less than 4.5% of federally backed loan dollars go to businesses led
						by women, even though women own more than 30% of the small businesses in the US?
					</b> 
					<br></br>
					<br></br>
					&emsp;&emsp;At Fundingverse, we take a stand for women entrepreneurs, breaking down barriers and helping them find the 
					capital they need to grow their businesses. We are committed to supporting entrepreneurs and small businesses 
					in developing thriving, sustainable, and impactful solutions for our world.
					</Typography>
				</Container>
			</Section>
		</Layout>
	);
};

export default MissionStatement;
